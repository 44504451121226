import React , { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,getSediCategorie, getElemento } from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import Dettagli from '../Elemento/Dettagli';
import Interventi from '../Elemento/Interventi';
import Manutenzioni from '../Elemento/Manutenzioni';
import Documenti from '../Elemento/Documenti';
import NavBar from '../../NavBar/NavBar';
import NavBar2 from '../../NavBar/NavBar2'
import MyElementBuilder from '../Elemento/MyElementBuilder'; 
import LoadingScreen from '../../LoadingScreen';

const Elemento = ({history}) => {
    const auth = firebase.auth();
    const db = firebase.firestore();
    const {id} = useParams()

    const [user,setUser] = useState(null)
    const [elemento,setElemento] = useState(null) //dati elemento
    const [section,setSection] = useState(0)
    const [interventi,setInterventi] = useState([])
    const [manutenzioni,setManutenzioni] = useState([])
    const [documenti,setDocumenti] = useState([])

    const [loading,setLoading] = useState(false)

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
        
         getElemento(db,user.Email,id,setElemento)      
      }
    
      },[user,id])

      function changeSection(i){
        if(i===1){
            if(interventi.length>0){
                setSection(1)

            }else{
              if(user.Tipo==='Lavoratore'){
                getInterventiOff()
              }else{
                getInterventi()
              }
                setSection(1)
            }
            
        }
        if(i===2){
            if(manutenzioni.length>0){
                setSection(2)

            }else{
              if(user.Tipo==='Lavoratore'){
                getManutenzioniOff()
              }else{
                getManutenzioni()
              }
                setSection(2)
            }
            
        }
        if(i===3){
            if(documenti.length>0){
                setSection(3)
            }else{
                getDocs()
                setSection(3)
            }
            
        }
        if(i===4){
              setSection(4)
      }

      }

      function getInterventi(){
        db.collection('Users').doc(user.Email).collection('Sedi').doc(elemento.Sede).collection('Categorie').doc(elemento.Categoria)
        .collection('Sottocategorie').doc(elemento.Macrocategoria).collection('Elementi').doc(elemento.Id).collection('Interventi').get().then((interventi)=>{
            interventi.docs.forEach((int)=>{
                db.collection('Attività').doc(int.data().ref).get().then((intz)=>{
                    console.log(intz.data())

                    setInterventi((prev)=>{
                        return [
                            ...prev,
                            {
                                ...intz.data(),
                                Id: intz.id
                            }
                            
                        ]
                    })
                })
            })
        })
      }
      function getInterventiOff(){
        if(elemento.Cliente){
          db.collection('Users').doc(elemento.Cliente).collection('Sedi').doc(elemento.Sede).collection('Categorie').doc(elemento.Categoria)
          .collection('Sottocategorie').doc(elemento.Macrocategoria).collection('Elementi').doc(elemento.Id).collection('Interventi').get().then((interventi)=>{
              interventi.docs.forEach((int)=>{
                  db.collection('Attività').doc(int.data().ref).get().then((intz)=>{
                      console.log(intz.data())
  
                      setInterventi((prev)=>{
                          return [
                              ...prev,
                              {
                                  ...intz.data(),
                                  Id: intz.id
                              }
                              
                          ]
                      })
                  })
              })
          })
        }
        
      }
      function getManutenzioni(){
        db.collection('Users').doc(user.Email).collection('Sedi').doc(elemento.Sede).collection('Categorie').doc(elemento.Categoria)
        .collection('Sottocategorie').doc(elemento.Macrocategoria).collection('Elementi').doc(elemento.Id).collection('Manutenzioni').get().then((interventi)=>{
            interventi.docs.forEach((int)=>{
                db.collection('Manutenzioni').doc(int.data().ref).get().then((intz)=>{
                    console.log(intz.data())

                    setManutenzioni((prev)=>{
                        return [
                            ...prev,
                            {
                                ...intz.data(),
                                Id: intz.id
                            }
                            
                        ]
                    })
                })
            })
        })
      }
      function getManutenzioniOff(){
        if(elemento.Cliente){
          db.collection('Users').doc(elemento.Cliente).collection('Sedi').doc(elemento.Sede).collection('Categorie').doc(elemento.Categoria)
          .collection('Sottocategorie').doc(elemento.Macrocategoria).collection('Elementi').doc(elemento.Id).collection('Manutenzioni').get().then((interventi)=>{
              interventi.docs.forEach((int)=>{
                  db.collection('Manutenzioni').doc(int.data().ref).get().then((intz)=>{
                      console.log(intz.data())
  
                      setManutenzioni((prev)=>{
                          return [
                              ...prev,
                              {
                                  ...intz.data(),
                                  Id: intz.id
                              }
                              
                          ]
                      })
                  })
              })
          })
        }
        
      }
      function getDocs(){
        setDocumenti([])
         db.collection('Elementi').doc(elemento.Id).collection('Documenti').get().then((docsz)=>{
          console.log(docsz.docs.length)
          if(docsz.docs.length>0){
            docsz.docs.forEach((docz)=>{
              console.log(docz.data())
              setDocumenti((prev)=>{
                return [
                  ...prev,
                  {
                    id: docz.id,
                    data: docz.data()
                    }
                  
                ]
              })
            })
          }
         }) 
      } //funzione che prende doc


  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      {loading===true?<LoadingScreen text={'Generazione PDF in corso, attendere prego...'}/>:null}
      {user?user.Tipo==='Lavoratore'?<NavBar2/>:<NavBar/>:null}
         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
      >

<div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>{elemento?elemento.Nome:null}</h4>
          <h4
          className="sub-text-b"
        >
          {elemento?elemento.Categoria:null} - {elemento?elemento.Macrocategoria:null}
        </h4>
          </div>

        </div>
        {elemento!==null?
        <div className="box-shadow" style={{display:'flex',width:'80vw',backgroundColor:'white',marginTop:'1.7rem',borderRadius:'15px',marginLeft:'3rem'}}>
        <div style={{alignItems:'center',height:'80vh',backgroundColor:'white',borderBottomLeftRadius:'15px',borderTopLeftRadius:'15px',width:'10rem',border:'1px solid #7d8da1'}}>
        <button onClick={()=>setSection(0)} style={{borderTopLeftRadius:'15px'}} className={section===0?"section-style-on":'section-style-off'}>Dettagli</button><br/>
        <button onClick={()=>changeSection(1)} className={section===1?"section-style-on":'section-style-off'}>Interventi</button><br/>
        <button onClick={()=>changeSection(2)} className={section===2?"section-style-on":'section-style-off'}>Manutenzioni</button>
        <button onClick={()=>changeSection(3)} className={section===3?"section-style-on":'section-style-off'}>Documenti</button>
        <button onClick={()=>changeSection(4)} className={section===4?"section-style-on":'section-style-off'}>MyElement Builder</button>

        </div>
       <div>
        {section===0?<Dettagli user={user} elemento={elemento}/>:null}
        {section===1?<Interventi user={user} elemento={elemento} interventi={interventi}/>:null}
        {section===2?<Manutenzioni user={user} setLoading={setLoading} manutenzioni={manutenzioni}/>:null}
        {section===3?<Documenti user={user} elemento={elemento} setDocumenti={setDocumenti} getDocs={getDocs} documenti={documenti}/>:null}
        {section===4?<MyElementBuilder history={history} id={id} user={user} elemento={elemento}/>:null}

        </div>
        </div>:<Loading/>}
       
      </div>
    </div>
  )
}

export default Elemento