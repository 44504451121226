import React,{useState} from 'react'
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import Draggable from 'react-draggable';
import PushPinIcon from '@mui/icons-material/PushPin';
const Sezione2 = (props) => {
  const [point1, setPoint1] = useState({ x: 40, y: 10 }); // Posizione iniziale punto 1
  const [point2, setPoint2] = useState({ x: 500, y: 10 }); // Posizione iniziale punto 2
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [isDraggable, setIsDraggable] = useState(true); // se è possibile fare il drag
  const [isZoom,setIsZoom] = useState(1); // valore dello zoom
  const [isDragging,setIsDragging] = useState(false); // Stato del drag
  const [visualizeError,setVisualizeError] = useState(false);

      function onImgLoad({ target: img }) {
        setImageDimensions({ width: img.offsetWidth, height: img.offsetHeight });
      }
    
  
     /* function onImgLoad({ target: img }) {
        setImageHeight(img.offsetHeight);
        setImageWidth(img.offsetWidth);
      }*/

        function error(){
          if(point1.x>=point2.x){
            setVisualizeError(true)
        }else if(point1.y<=point2.y){
          setVisualizeError(true)
        }else{
          setVisualizeError(false)
        }
        
      }


  const handleDragPoint1 = (e, data) => {
    setIsDragging(true)
    setIsDraggable(true)
    const { x, y } = data;
    // Applica la scala per correggere il posizionamento
     // Calcolare la differenza tra la posizione attuale del cursore e la posizione precedente
  const deltaX = x - point1.x;
  const deltaY = y - point1.y;

  // Adattare il movimento tenendo conto dello zoom
  const scaleFactor = 1 / isZoom;

  // Calcolare la nuova posizione del marker con la compensazione dello zoom
  const scaledX = point1.x + deltaX * scaleFactor;
  const scaledY = point1.y + deltaY * scaleFactor;

    setPoint1({ x:scaledX, y:scaledY});
  };

  const handleDragPoint2 = (e, data) => {
    setIsDragging(true)
    setIsDraggable(true)
    const { x, y } = data;
    // Applica la scala per correggere il posizionamento
    const deltaX = x - point2.x;
    const deltaY = y - point2.y;
  
    // Adattare il movimento tenendo conto dello zoom
    const scaleFactor = 1 / isZoom;
  
    // Calcolare la nuova posizione del marker con la compensazione dello zoom
    const scaledX = point2.x + deltaX * scaleFactor;
    const scaledY = point2.y + deltaY * scaleFactor;
  
      setPoint2({ x:scaledX, y:scaledY});
  };

  const confirm = () => {
    console.log(point1,point2)
    if (point1.x >= point2.x) {
      alert('Errore: X del PUNTO 1 maggiore o uguale al PUNTO 2.');
    } else if (point1.y <= point2.y) {
      alert('Errore: Y del PUNTO 1 maggiore o uguale al PUNTO 2.');
    } else {
      props.setWidth1(point1.x);
      props.setWidth2(point2.x);
      props.setHeight1(point1.y);
      props.setHeight2(point2.y);
      props.setHeightImage(imageDimensions.height);
      props.setWidthImage(imageDimensions.width);
      props.setSection(2);
    }
  };

    /*  function confirm(){
        console.log(height1,height2)
        if(width1>=width2){
          alert('Errore: X del PUNTO 1 maggiore del PUNTO 2.')
        }else if(height1<=height2){
          alert('Errore: Y del PUNTO 1 maggiore del PUNTO 2.')
        }else{
          props.setWidth1(width1)
          props.setWidth2(width2)
          props.setHeight1(height1)
          props.setHeight2(height2)
          props.setHeightImage(heightImage)
          props.setWidthImage(widthImage)
          props.setSection(2)

        }
      }*/

  return (
    <div style={{width:'80vw',height:'80vh',marginLeft:'auto',marginRight:'auto',marginTop:'5vh',display:'flex'}}>
        <div style={{width:'35vw',height:'80vh'}}>
        <div style={{width:'30vw'}}>
        <h4 className='header-normal' style={{marginBottom:'1vh'}}>Istruzioni:</h4>

        <h4 className='sub-text'>Posiziona il <b style={{color:'#192bc2'}}>PUNTO 1</b>  <PushPinIcon style={{color:'blue',height:'20px',width:'20px'}}></PushPinIcon>in un punto qualunque del lato sinistro della planimetria, <b>più in basso del </b><b style={{color:'red'}}>PUNTO 2</b> <PushPinIcon style={{color:'red',height:'20px',width:'20px'}}></PushPinIcon>.</h4>
            <h4 className='sub-text' style={{marginTop:'4vh'}}>Posiziona il <b style={{color:'red'}}>PUNTO 2</b> <PushPinIcon style={{color:'red',height:'20px',width:'20px'}}></PushPinIcon> in un punto qualunque del lato destro della planimetria <b>più in alto del </b><b style={{color:'#192bc2'}}>PUNTO 1</b> <PushPinIcon style={{color:'blue',height:'20px',width:'20px'}}></PushPinIcon>.</h4>

<div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'4vh'}}>
<button className='main-button'  onClick={()=>confirm()}>CONFERMA</button>
</div>
    </div>

        </div>
        
        <div style={{ maxWidth: '40rem',height:'fit-content', position: 'relative', marginLeft: '2vw',cursor:isDragging?'none':'default' }}>
       {/* <div style={{ width: "35rem", position: "relative",marginLeft:'8vw',overflowY:'auto' }}>
        <div
                style={{
                  position: "absolute",
                  backgroundColor: "blue",
                  top: height1 + "px",
                  left: width1 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>1</h4></div>
              <div
                style={{
                  position: "absolute",
                  backgroundColor: "red",
                  top: height2 + "px",
                  left: width2 + "px",
                  width: "1rem",
                  height: "1rem",
                  borderRadius: 50,
                  padding:2,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center'
                }}
              ><h4 style={{margin:0,color:'white',width:'fit-content',height:'fit-content',fontSize:'15px'}}>2</h4></div>
        <img src={props.planimetria} alt='planimetria' onLoad={onImgLoad} style={{ maxWidth: "100%"}}></img>
        </div>*/}
        <h4 className='header-normal' style={{marginBottom:'1vh'}}>Planimetria:</h4>
        {visualizeError&&<h4 className='sub-text' style={{color:'red',marginBottom:'1vh'}}>Errore: Il punto 1 deve essere più in basso e a sinistra del punto 2.</h4>}
         <div style={{border:visualizeError===true?'1px solid red':'1px solid lightgrey' }}>
         <TransformWrapper 
          panning={{ disabled: isZoom===1||isDraggable===true }} 
          onZoom={(e)=>{setIsZoom(e.state.scale)}}
           >
          <TransformComponent>
            <div style={{ width: '40rem', position: 'relative', overflow: 'hidden'}}>
           
              <Draggable position={point1} onDrag={handleDragPoint1} onStop={()=>{setIsDraggable(false);setIsDragging(false);error()}} bounds="parent">
                <div
                  style={{
                    position: 'absolute',
                    width: '1px',
                    height: '5px',
                    cursor: isDragging?'none':'grab',
                    zIndex:100
                  }}
                >
                  <div style={{width:'1px',height:'5px',position:'relative',}}>
                  {isDragging?null:  <div style={{position:'absolute',top:'-14px',right:'-8.45px',borderRadius:'50%'}}>
                    <PushPinIcon style={{color:'blue',height:'20px',width:'20px'}}></PushPinIcon>
                    </div>}
                    {isDragging?<div style={{position:'absolute',backgroundColor:'blue',bottom:0,right:0,width:'3px',height:'3px',borderRadius:'50%'}}></div>:null}
                  </div>
                </div>
              </Draggable>
              <Draggable position={point2} onDrag={handleDragPoint2} onStop={()=>{setIsDraggable(false);setIsDragging(false);error()}} bounds="parent">
              <div
                  style={{
                    position: 'absolute',
                    width: '1px',
                    height: '1px',
                    
                    cursor: isDragging?'none':'grab',
                    zIndex:100
                  }}
                >
                  <div style={{  width: '1px',
                    height: '1px',position:'relative',}}>
                     {isDragging?null:  <div style={{position:'absolute',top:'-17px',right:'-8.45px',borderRadius:'50%'}}>
                    <PushPinIcon style={{color:'red',height:'20px',width:'20px'}}></PushPinIcon>
                    </div>}
                    {isDragging?<div style={{position:'absolute',backgroundColor:'red',bottom:0,right:0,width:'3px',height:'3px',borderRadius:'50%'}}></div>:null}
                  </div>
                </div>
              </Draggable>
              <img
                src={props.planimetria}
                alt="planimetria"
                onLoad={onImgLoad}
                style={{ maxWidth: '100%',zIndex:99 }}
              />
            </div>
          </TransformComponent>
        </TransformWrapper>
        </div>
        </div>
    </div>
  )
}

export default Sezione2