//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import { formatData } from "../../EngineClienti/engineClienti";
import AssignmentLateRoundedIcon from '@mui/icons-material/AssignmentLateRounded';
import GenerazionePdf from "../../GenerazionePdf";
import DownloadIcon from '@mui/icons-material/Download';
const Manutenzioni = (props) => {

    const db = firebase.firestore(); //database firebase

    const [manutenzioni,setManutenzioni] = useState([])

    useEffect(()=>{
      setManutenzioni(props.manutenzioni)
    },[props.manutenzioni])

  function getSubScadenze(scadId, sede) {
      return new Promise((resolve, reject) => {
        const subScadenze = []; // Array per tutte le sottoscadenze
    
        db.collection("Users")
        .doc(props.user.Email)
        .collection("Sedi")
        .doc(sede)
        .collection("Manutenzioni")
        .doc(scadId)
        .collection('Manutenzioni')
          .get()
          .then((querySnapshot) => {
            const promises = querySnapshot.docs.map((actz) => {
              return db.collection('Manutenzioni').doc(actz.data().ref).get().then((act) => {
                const subScadenza = {
                  key: act.id,
                  Titolo: act.data().Titolo || '',
                  Sede: act.data().Sede || '',
                  Descrizione: act.data().Descrizione || '',
                  Nome: act.data().Nome || '',
                  Cognome: act.data().Cognome || '',
                  Priorità: act.data().Priorità || '',
                  Oggetto: act.data().Oggetto || '',
                  Stato: act.data().Stato || '',
                  Cliente: act.data().Cliente || '',
                  Sede_cli: act.data().Sede_cli || '',
                  Elementi: act.data().Elementi || [],
                  Report: act.data().Report || '',
                  Esito: act.data().Esito || '',
                  SottoAttività: act.data().SottoAttività ? act.data().SottoAttività : false,
                  Indirizzo: act.data().Indirizzo || '',
                  Nome_AziendaCli: act.data().Nome_AziendaCli || '',
                  Nome_AziendaOff: act.data().Nome_AziendaOff || '',
                  InterventiPath: act.data().InterventiPath || '',
                  Categoria: act.data().Categoria || '',
                  Macrocategoria: act.data().Macrocategoria || '',
                  Operatore: props.user.Tipo === 'Lavoratore' || props.user.TipoSott === 'Lavoratore'
                    ? (act.data().Operatore ? act.data().Operatore : props.user.Tipo === 'SottoAccount' ? props.user.MasterAccount : props.auth.currentUser.email)
                    : (act.data().Operatore ? act.data().Operatore : props.user.Fornitore),
                  Archiviato: act.data().Archiviato ? act.data().Archiviato : false,
                  Type: act.data().Type ? act.data().Type : null,
                };
    
                subScadenze.push(subScadenza);
              });
            });
    
            // Una volta completate tutte le promesse, risolviamo la promessa principale con i dati raccolti
            Promise.all(promises)
              .then(() => resolve(subScadenze))
              .catch(reject); // In caso di errore, lo gestiamo qui
          })
          .catch(reject); // Gestione dell'errore nel caso in cui il get fallisca
      });
    }

       async function handleManutenzionePdf(manutenzionee) {
            try {
              props.setLoading(true)
              const manutenzione = { ...manutenzionee }; // Clona l'oggetto per evitare mutazioni indesiderate
              console.log(manutenzione)
              // Recupera i dati dell'operatore
              const operatoreDoc = await db.collection("Users").doc(manutenzione.Operatore).get();
              if (!operatoreDoc.exists) {
                throw new Error(`Operatore con ID ${manutenzione.Operatore} non trovato.`);
              }
              const operatoreData = operatoreDoc.data();
              const operatore = {
                Nome_Azienda: operatoreData.Nome_Azienda || '',
                Nome: operatoreData.Nome || '',
                Cognome: operatoreData.Cognome || '',
                Indirizzo: operatoreData.Indirizzo || '',
                Sede: operatoreData.Comune || '',
                Telefono: operatoreData.Numero || '',
              };
          
              // Recupera i dati del cliente
              const clienteDoc = await db.collection("Users").doc(manutenzione.Cliente).get();
              if (!clienteDoc.exists) {
                throw new Error(`Cliente con ID ${manutenzione.Cliente} non trovato.`);
              }
              const clienteData = clienteDoc.data();
              manutenzione.Nome_AziendaCli = clienteData.Nome_Azienda || '';
              manutenzione.Numero_telefono_cli = clienteData.Numero || '';
              manutenzione.Sede_cli = manutenzione.SedeName?manutenzione.SedeName!==''?manutenzione.SedeName:manutenzione.Sede:manutenzione.Sede || '';
          
              // Inizializza la lista degli elementi e delle sottoscadenze
              const listaElementi = [];
              const listaSottoscadenze = [];
          
              // Funzione per recuperare i dettagli di un elemento
              const getElementoDetails = async (elePath) => {
                const path = elePath.Elemento ? elePath.Elemento : elePath;
                const eleRef = await db.doc(path).get();
                if (!eleRef.exists) return null;
                const eleDataRef = await db.collection("Elementi").doc(eleRef.data().ref).get();
                if (!eleDataRef.exists) return null;
                return {
                  Nome: eleDataRef.data().Nome || 'Nome non disponibile',
                  Esito: elePath.Esito || 'Non specificato',
                };
              };
          
              // Recupera le sottoscadenze se SottoAttività è true
              if (manutenzione.SottoAttività) {
                console.log(manutenzione)
                const subScadenze = await getSubScadenze(manutenzione.Id, manutenzione.Cliente);
                console.log(subScadenze)
                // Recupera gli elementi per ciascuna sottoscadenza
                for (const sottoscadenza of subScadenze) {
                  const elementiSotto = [];
                  if (sottoscadenza.Elementi && sottoscadenza.Elementi.length > 0) {
                    for (const ele of sottoscadenza.Elementi) {
                      const elementoDettagli = await getElementoDetails(ele);
                      if (elementoDettagli) {
                        elementiSotto.push(elementoDettagli);
                      }
                    }
                  }
          
                  // Aggiungi la sottoscadenza alla lista
                  listaSottoscadenze.push({
                    ...sottoscadenza,
                    Elementi: elementiSotto,
                  });
                }
              }
          
              // Recupera gli elementi della manutenzione principale
              if (manutenzione.Oggetto && manutenzione.Oggetto.includes('Documento:')) {
                const toGet = manutenzione.Titolo.split(' ').slice(1).join(' ');
                manutenzione.Oggetto = toGet;
                listaElementi.push({
                  Nome: toGet,
                  Esito: manutenzione.Esito || 'Non specificato',
                });
              } else if (manutenzione.Elementi && manutenzione.Elementi.length > 0) {
                for (const ele of manutenzione.Elementi) {
                  const elementoDettagli = await getElementoDetails(ele);
                  if (elementoDettagli) {
                    listaElementi.push(elementoDettagli);
                  }
                }
              }
          
              // Genera il PDF passando tutte le informazioni necessarie
              GenerazionePdf.generaPdfManutenzione(
                manutenzione,
                operatore,
                listaElementi,
                listaSottoscadenze,
                props.setLoading
              );
            } catch (error) {
              console.error("Errore durante la generazione del PDF della manutenzione:", error);
              // Puoi gestire ulteriormente l'errore qui, ad esempio mostrando una notifica all'utente
            }
          } //funzione che genera la manutenzione pdf//funzione che genera la manutenzione pdf



    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',width:'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <AssignmentLateRoundedIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Manutenzioni elemento</h4>
      </div>
      {manutenzioni.length>0?<div style={{marginTop:'1rem'}}>
        {manutenzioni.map((int,n)=>(
          <div key={n} style={{marginTop:'1rem'}}>
            <h4 className="header-normal" style={{color:'#192bc2'}}>{int.Titolo}</h4>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Stato: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Stato}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Esito: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Esito?int.Esito:'-'}</h4>
            </div>
            <div style={{display:'flex',alignItems:'center'}}>
            <h4 className="sub-text">Data di intervento: </h4>
            <h4 className="sub-text" style={{marginLeft:'5px', color:'black'}}>{int.Data?formatData(int.Data):'-'}</h4>
            </div>
            <div onClick={()=>handleManutenzionePdf(int)} style={{display:'flex',alignItems:'center',cursor:'pointer',width:'fit-content',marginTop:'10px'}}>
            <h4  className="medium-text" style={{marginLeft:0}}>Scarica PDF</h4>
            <DownloadIcon style={{color:'black',fontSize:'1rem'}}/>
            </div>
          </div>
        ))}
      </div>:<h4 style={{marginTop:'1rem'}} className="medium-text">Nessuna manutenzione aggiunta...</h4>}

      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
    </div>
}

export default Manutenzioni;